<template>
	<v-sheet flat>
		<mw-edit :id="id" storeModule="users" :model="model" title-field="name">
			<template v-slot:email="slotProps">
				<v-text-field
					v-model="slotProps.data.email"
					:label="$t('misc.email')"
					@input="(v) => (slotProps.data['email'] = parseEmail(v))"
					:rules="[(v) => mwutils.validateEmail(v) || 'Invalid Email']"
				></v-text-field>
			</template>
		</mw-edit>
		<secondary-navigation>
			<template v-slot:main>
				<div v-if="id" class="ma-2">
					<nav-item
						:link="`/communication-history/${id}`"
						text="Communication History"
						icon="mdi-email"
					></nav-item>
					<nav-item
						:link="`/user-appointments/${id}`"
						text="Appointments"
						icon="mdi-calendar-account"
					></nav-item>
					<nav-item
						:link="`/payment-info/${id}`"
						text="Payment Info"
						icon="mdi-currency-gbp"
					></nav-item>
					<nav-item
						:link="`/user-profile/${id}`"
						text="User Profile"
						icon="mdi-target"
					></nav-item>
					<v-divider class="mb-4 mt-3"></v-divider>
					<send-email-button
						class="mb-5"
						:values="{ site_url: 'https://x.m.works', user: id }"
						email="registration_reminder"
					>
						<template v-slot:button="scope">
							<v-btn block small @click="scope.click" color="primary">
								Send Email Reminder
							</v-btn>
						</template>
					</send-email-button>
				</div>
			</template>
		</secondary-navigation>
		<!-- <v-btn color="green" dark link target="_blank" :to="`/support/${id}`">Provide Video Support</v-btn> -->
	</v-sheet>
</template>

<style lang="less"></style>

<script type="text/javascript">
import User from "@/models/User";
import MwEdit from "@c/ui/MwEdit";
import SendEmailButton from "@c/communication/SendEmailButton";
import SecondaryNavigation from "@c/navigation/Secondary";
import NavItem from "@/components/navigation/Item";

export default {
	name: "EditParticipant",
	props: {
		id: { type: String }, // @doc the id string of the object - will add a new document if not passed
	},
	computed: {
		model() {
			return User;
		},
		user() {
			return this.$store.state.users.data[this.id];
		},
	},
	methods: {
		parseEmail(a) {
			return a.toLowerCase().trim();
		},
	},
	components: {
		MwEdit,
		SendEmailButton,
		SecondaryNavigation,
		NavItem,
	},
	created() {
		this.$store.dispatch("incentives/start")
	}
};
</script>
