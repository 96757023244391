export default {     
	first_name: {
		value: "first_name",
		default: "",
		display: true,
		component: "v-text-field",
		label: "First Name",
	},	
	last_name: {
		value: "last_name",
		default: "",
		display: true,
		component: "v-text-field",
		label: "Last Name",
	},
	name: {
		value: "name",
		default: "",
		display: true,
		component: "v-text-field",
		label: "Name",
	},
	email: {
		value: "email",
		default: "",
		display: true,
        component: "v-text-field",
		label: "Email",
	},
	phone: {
		value: "phone",
		default: "",
		display: true,
        component: "v-text-field",
		label: "Phone",
	},
	status: {
		value: "status", 
		default: "published", 
		display: false, 
		component: "v-select", 
		label: "Status", 
		items: ["published", "replaced", "updated", "new", "pending"]
	}, 
	role: {
		value: "role", 
		default: "", 
		display: true, 
		component: "v-select", 
		label: "Role", 
		items: ["client", "participant", "project_manager", "contract_manager"]
	}, 
	grade: {
		value: "grade", 
		default: "orange", 
		display: true, 
		component: "v-select", 
		label: "Grade", 
		items: ["pineapple", "banana", "orange", "apple", "strawberry" ]
	}, 
	notes: {
		value: "notes",
		default: "",
		display: true,
		component: "v-textarea",
		label: "Notes",
	},
	registration_method: {
		value: "registration_method", 
		default: "admin"
	},
	organisation: {
		value: "organisation",
		default: [],
		display: false,
		multiple: false,
		label: "Organisation",
		component: "MwSelect", 
		options: {
			storeModule: "organisations",
			newPath: "NewOrganisation",			
			itemText: "name",

		},
		require_id: false
	},
}
